import { useState } from 'react'
import { PaidOutlined } from '@mui/icons-material'
import { Box, Button, TextField, Typography, useTheme } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import PriceNoDecimalsThousandsSeparatorMask from '@UI/sharedComponents/InputMasks/PriceNoDecimalsThousandsSeparatorMask'

import { useFormatter, useTranslations } from 'next-intl'

import { useIsMobile } from '@liv-frontend/ui'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

const { useRouter } = getLivSharedPathnamesNavigation()

export const operationCost = [
  { fromPrice: 0, toPrice: 7000, policyCost: 2500 },
  { fromPrice: 7001, toPrice: 10000, policyCost: 2500 },
  { fromPrice: 10001, toPrice: 15000, policyCost: 2500 },
  { fromPrice: 15001, toPrice: 19999, policyCost: 2500 },
  { fromPrice: 20000, toPrice: 25000, policyCost: 3000 },
  { fromPrice: 25001, toPrice: 30000, policyCost: 3000 },
  { fromPrice: 30001, toPrice: 35000, policyCost: 4000 },
  { fromPrice: 35001, toPrice: 40000, policyCost: 4000 },
  { fromPrice: 40001, toPrice: 45000, policyCost: 5500 },
  { fromPrice: 45001, toPrice: 50000, policyCost: 5500 },
  { fromPrice: 50001, toPrice: 55000, policyCost: 5500 },
  { fromPrice: 55001, toPrice: 60000, policyCost: 6500 },
  { fromPrice: 60001, toPrice: 65000, policyCost: 6500 },
  { fromPrice: 65001, toPrice: 70000, policyCost: 6500 },
  { fromPrice: 70001, toPrice: 75000, policyCost: 8000 },
  { fromPrice: 75001, toPrice: 80000, policyCost: 8000 },
  { fromPrice: 80001, toPrice: 85000, policyCost: 11000 },
  { fromPrice: 85001, toPrice: 90000, policyCost: 11000 },
  { fromPrice: 90001, toPrice: 95000, policyCost: 14000 },
  { fromPrice: 95001, toPrice: 100000, policyCost: 14000 },
  { fromPrice: 100001, toPrice: Number.MAX_SAFE_INTEGER, policyCost: 15000 },
]

export const getCost = (monthlyRent: number): number => {
  for (const cost of operationCost) {
    if (cost.fromPrice <= monthlyRent && monthlyRent <= cost.toPrice) {
      return cost.policyCost
    }
  }
  return 0
}

function Calculator(): JSX.Element {
  const isMobile = useIsMobile()
  const theme = useTheme()
  const router = useRouter()
  const formatter = useFormatter()
  const t = useTranslations('Calculator')
  const [rentValue, setRentValue] = useState(0)
  const [calculatedPrice, setCalculatedPrice] = useState(0)
  const BASE_INVESTIGATIONS_PRICE = 1000

  const handleCalculate = (): void => {
    const cost = getCost(rentValue) + BASE_INVESTIGATIONS_PRICE // adding the cost of the investigations
    setCalculatedPrice(cost)
  }
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: 2,
        border: '1px solid #D6D6D6',
      }}
    >
      <Grid container padding={2} spacing={2} alignItems="center">
        <Grid sm={12} md={7} padding={2} spacing={2} width="100%">
          <Box width="100%">
            <Typography variant="h4" marginBottom={4}>
              {t('Title')}
            </Typography>
            <Grid container padding={2} spacing={2} alignItems="center">
              <Grid xs={12} sm={7}>
                <TextField
                  type="number"
                  label={t('InputText')}
                  fullWidth
                  onChange={(event) => {
                    const { value } = event.target
                    setRentValue(Number(value))
                  }}
                  InputProps={{
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- Same as calculator
                    inputComponent:
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Same as calculator
                      PriceNoDecimalsThousandsSeparatorMask as any,
                  }}
                />
              </Grid>
              <Grid xs={12} sm={5}>
                <Button
                  variant="outlined"
                  endIcon={<PaidOutlined />}
                  onClick={handleCalculate}
                  size="large"
                  fullWidth={isMobile}
                  sx={{ height: '56px' }}
                >
                  {t('ButtonText')}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid sm={12} md={5} padding={2} spacing={2} width="100%">
          <Grid
            container
            alignItems="center"
            padding={0}
            spacing={2}
            width="100%"
          >
            <Grid xs={12} width="100%">
              <Typography
                color={theme.palette.text.primary}
                textAlign="center"
                variant="h4"
              >
                {calculatedPrice ? t('Price') : t('PriceFrom')}
              </Typography>
            </Grid>
            <Grid xs={12} width="100%">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
              >
                <Typography
                  component="span"
                  variant="h4"
                  color={theme.palette.primary.main}
                  fontWeight={700}
                >
                  $
                </Typography>
                <Typography
                  component="span"
                  variant="h2"
                  color={theme.palette.primary.main}
                  fontWeight={700}
                >
                  {formatter.number(calculatedPrice || 3500)}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12} width="100%" display="flex" justifyContent="center">
              <Button
                variant="contained"
                onClick={() => router.push('/user/sign-up/')}
                size="large"
                fullWidth
              >
                {t('ButtonText2')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Calculator
