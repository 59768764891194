import {
  Box,
  Button,
  Container,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'

import { Nunito } from 'next/font/google'
import Image from 'next/image'
import { useTranslations } from 'next-intl'

import { useIsMobile } from '@liv-frontend/ui'

import { getLivSharedPathnamesNavigation } from '@lib/livUseRouter'

const nunito = Nunito({
  subsets: ['latin'],
  display: 'swap',
})

const { useRouter } = getLivSharedPathnamesNavigation()

function Hero(): JSX.Element {
  const isMobile = useIsMobile()
  const router = useRouter()
  const theme = useTheme()
  const t = useTranslations('LandingPage.HomePage.Hero')

  const handleButtonClick = (): void => {
    const section = document.getElementById('products')
    if (section) {
      section.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }

  return (
    <Box
      sx={{
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.00) 39.78%, rgba(2, 186, 21, 0.05) 88.15%, rgba(2, 186, 186, 0.15) 100.11%), #FFF',
        height: 'auto',
        width: '100%',
        position: 'relative',
        pt: '100px',
        bottom: '100px',
        mb: '-100px',
      }}
    >
      <Container>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            mt: 1.5,
          }}
        >
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
            }}
          >
            <Box
              component={isMobile ? 'span' : 'div'}
              width={1}
              textAlign="center"
              sx={{ whiteSpace: 'normal' }} // Add this line
              maxWidth="791px"
            >
              <Typography
                variant="h2"
                color={theme.palette.text.primary}
                fontWeight={400}
                component="span"
              >
                {t('Title')}
                <Typography
                  variant="h2"
                  fontFamily={nunito.style.fontFamily}
                  color={theme.palette.primary.main}
                  gutterBottom
                  fontWeight={700}
                  component="span"
                >
                  {t('Title2')}
                </Typography>
              </Typography>
            </Box>
          </Box>

          <Typography
            variant="subtitle1"
            align="center"
            sx={{
              color: theme.palette.text.secondary,
              variant: 'body1',
              mt: 2,
              mb: isMobile ? 3 : 2.5,
            }}
          >
            {t('Subtitle')}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <Button
              variant="contained"
              onClick={() =>
                router.push(
                  '/info/sample/legal-policies/details/21cc1e79-8dfa-481d-8142-e9b1dc338e21/',
                )
              }
              sx={{ textTransform: 'none' }}
            >
              {t('CTA1')}
            </Button>
            <Button
              variant="outlined"
              onClick={handleButtonClick}
              sx={{ textTransform: 'none' }}
            >
              {t('CTA2')}
            </Button>
          </Box>
          <Image
            src="/assets/images/landing/hero.png"
            alt="home image"
            width={900}
            height={498}
            sizes="100vw"
            style={{
              objectFit: 'contain',
              width: '100%',
              height: 'auto',
            }}
            priority
            quality={100}
          />
        </Stack>
      </Container>
      <Box
        id="products"
        sx={{
          bottom: 0,
          height: '73px',
          position: 'absolute',
          width: '100%',
        }}
      />
    </Box>
  )
}

export default Hero
